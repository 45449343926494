

























import VueTypedMixins from 'vue-typed-mixins'
import ChainSearchMixin from '@/mixin/componentsMixin/ChainSearch'
export default VueTypedMixins(ChainSearchMixin).extend({
  data() {
    return {
      searchValue: '',
    }
  },
})
