





























































import Vue from "vue";
export default Vue.extend({
  props: {
    txCount: Number,
    height: Number,
    blockTime: Number,
    packagingAddress: String,
    hash: String,
  },
});
