




































































































import Vue from "vue";
import TxStatus from "../pc/txStatus.vue";
import txGroupIcon from "@/assets/images/blockChainBrowser/tradeDetail/txGroupIcon.png";
export default Vue.extend({
  components: {
    TxStatus,
  },
  props: {
    blockTime: Number,
    tradeG: Number,
    amount: Number,
    txHash: String,
    from: String,
    to: String,
    status: Boolean,
    row: Object,
    hideStatus: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      txGroupIcon,
    };
  },
  computed: {
    txGroup() {
      if (this.tradeG === 1 || this.tradeG === 2) {
        return "isGroupItem";
      } else if (this.tradeG === 3) {
        return "isGroupItemEnd";
      } else {
        return "";
      }
    },
  },
});
