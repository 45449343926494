var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-3.5 txItem flow-root relative",class:[_vm.txGroup ? 'bg-lightBlue2' : 'bg-white']},[(_vm.txGroup)?_c('div',{staticClass:"leftLine"}):_vm._e(),(_vm.txGroup && _vm.txGroup === 'isGroupItem')?_c('img',{staticClass:"w-5 h-5 txGroupIcon",attrs:{"src":_vm.txGroupIcon,"alt":"txGroupIcon"}}):_vm._e(),_c('div',{staticClass:"flex justify-between items-center",staticStyle:{"margin-top":"14px"}},[_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"text-text-color text-xs",staticStyle:{"margin-right":"3px"}},[_vm._v(" "+_vm._s(_vm.row.action_name == "miner" ? _vm.$t("lang.trade.pkgRewards") : _vm.$t("lang.trade.txAmount"))+" : ")]),_c('div',{staticClass:"text-darkBlue text-sm font-bold"},[_vm._v(" "+_vm._s(_vm._f("TradeValue")(_vm.row))+" ")])]),_c('div',{staticClass:"text-xs text-text-color"},[_vm._v(" "+_vm._s(_vm._f("formatTime")(_vm.blockTime))+" ")])]),_c('div',{staticClass:"flex items-center justify-between",staticStyle:{"margin-top":"3px","margin-bottom":"3px"}},[_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"text-text-color text-xs",staticStyle:{"margin-right":"3px"}},[_vm._v(" "+_vm._s(_vm.$t("lang.txDetail.txHash"))+" : ")]),_c('div',{staticClass:"text-xs",class:[_vm.txGroup ? ' text-lightBlue' : 'text-footer-color']},[_c('router-link',{attrs:{"to":{
            path: '/tradeHash',
            query: {
              hash: _vm.txHash,
            },
          }}},[_vm._v(" "+_vm._s(_vm._f("filterHash")(_vm.txHash))+" ")])],1)]),(!_vm.hideStatus)?_c('TxStatus',{attrs:{"size":"small","status":_vm.status}}):_vm._e()],1),_c('div',{staticClass:"flex items-center justify-between"},[_c('div',[_c('span',{staticClass:"text-text-color text-xs"},[_vm._v(" "+_vm._s(_vm.$t("lang.trade.sender"))+" : ")]),_c('span',{staticClass:"text-xs",class:[_vm.txGroup ? ' text-lightBlue' : 'text-footer-color']},[_c('router-link',{attrs:{"to":{
            path: '/address',
            query: {
              address: _vm.from,
            },
          }}},[_vm._v(" "+_vm._s(_vm._f("filterHash")(_vm.from))+" ")])],1)]),_c('div',[_c('span',{staticClass:"text-text-color text-xs"},[_vm._v(" "+_vm._s(_vm.$t("lang.trade.receiver"))+" : ")]),_c('span',{staticClass:"text-xs",class:[_vm.txGroup ? ' text-lightBlue' : 'text-footer-color']},[_c('router-link',{attrs:{"to":{
            path: '/address',
            query: {
              address: _vm.to,
            },
          }}},[_vm._v(" "+_vm._s(_vm._f("filterHash")(_vm.to))+" ")])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }