


















































import VueTypedMixins from 'vue-typed-mixins'
import tradeMixin from '@/mixin/trade'
import MBlockItem from '@/components/mobile/m-blockItem.vue'
import mChainSearch from '@/components/mobile/m-chainSearch.vue'
import MPageContainer from '@/components/mobile/m-pageContainer.vue'
import MTitle from '@/components/mobile/m-title.vue'
import MTxItem from '@/components/mobile/m-txItem.vue'
export default VueTypedMixins(tradeMixin).extend({
  components: { mChainSearch, MPageContainer, MTitle, MBlockItem, MTxItem },
})
