var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-trade mx-4"},[_c('m-chain-search',{staticClass:"mt-3.5"}),_c('m-page-container',{staticClass:"mt-5",attrs:{"currentPage":_vm.pages.currentPage,"pageSize":_vm.pages.pageSize,"total":_vm.pages.total},on:{"pageChange":_vm.pageChange,"sizeChange":_vm.sizeChange}}),_c('div',{staticClass:"shadow-shadow1 mt-3.5 rounded-twoPx rounded-b-twoPx"},[_c('m-title',{style:({
        'border-bottom-left-radius': '2px',
        'border-bottom-right-radius': '2px',
      }),attrs:{"title":((_vm.$t('lang.trade.title')) + "(" + (_vm.filterNum(_vm.total)) + (_vm.$t(
        'lang.trade.count'
      )) + ") (" + (_vm.$t('lang.trade.tip')) + ")")}}),_vm._l((_vm.txList),function(n,i){return _c('m-tx-item',{key:i,style:({
        'border-bottom-left-radius': i === _vm.txList.length - 1 ? '2px' : 'none',
        'border-bottom-right-radius':
          i === _vm.txList.length - 1 ? '2px' : 'none',
      }),attrs:{"tradeG":n.tradeG,"row":n,"blockTime":n.block_time,"amount":n.amount,"txHash":n.hash,"from":n.from,"to":n.to,"status":n.success}})})],2),_c('m-page-container',{staticClass:"mt-5",attrs:{"simple":false,"currentPage":_vm.pages.currentPage,"pageSize":_vm.pages.pageSize,"total":_vm.pages.total},on:{"pageChange":_vm.pageChange,"sizeChange":_vm.sizeChange}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }