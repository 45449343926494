






























import Vue from 'vue'
export default Vue.extend({
  name: 'pages',
  components: {},
  data() {
    return {
      currentPageNum: this.currentPage,
      firstPageBtnDisabled: true,
      lastPageBtnDisabled: false,
    }
  },
  props: {
    currentPage: {
      type: Number,
      default: 1,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    total: {
      type: Number,
      default: 0,
    },
    simple: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    currentPage(newVal) {
      this.currentPageNum = newVal
      this.firstPageBtnDisabled = this.currentPageNum === 1 ? true : false
      this.lastPageBtnDisabled =
        this.currentPageNum === this.lastPageNum ? true : false
    },
  },
  computed: {
    lastPageNum(): number {
      return Math.ceil(this.total / this.pageSize)
    },
  },
  methods: {
    handleCurrentChange(page: number) {
      this.firstPageBtnDisabled = page === 1 ? true : false
      this.lastPageBtnDisabled = page === this.lastPageNum ? true : false
      this.currentPageNum = page
      this.$emit('pageChange', page)
    },
    handleSizeChange(size: number) {
      this.$emit('sizeChange', size)
    },
  },
})
