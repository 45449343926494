var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"blockItem bg-white flex pr-3.5"},[_c('div',{staticClass:"txNumBox"},[_c('router-link',{attrs:{"to":{
        path: '/blockDetail',
        query: {
          height: _vm.height,
        },
      }}},[_vm._v(" "+_vm._s(_vm.height)+" ")])],1),_c('div',{staticClass:"msgBox flex-shrink-0 flex-grow mt-3.5 ml-2"},[_c('div',[_c('span',{staticClass:"text-text-color text-xs"},[_vm._v(" "+_vm._s(_vm.$t("lang.block.hash"))+"： ")]),_c('router-link',{attrs:{"to":{
          path: '/blockDetail',
          query: {
            height: _vm.height,
          },
        }}},[_c('span',{staticClass:"text-footer-color"},[_vm._v(" "+_vm._s(_vm._f("filterHash")(_vm.hash,9))+" ")])])],1),_c('div',[_c('span',{staticClass:"text-text-color text-xs",staticStyle:{"margin-right":"9px"}},[_vm._v(" "+_vm._s(_vm.$t("lang.block.packingAddress"))+": ")]),_c('span',{staticClass:"text-footer-color"},[_c('router-link',{attrs:{"to":{
            path: '/address',
            query: {
              address: _vm.packagingAddress,
            },
          }}},[_vm._v(" "+_vm._s(_vm._f("filterHash")(_vm.packagingAddress,9))+" ")])],1)]),_c('div',{staticClass:"flex justify-between items-center w-full"},[_c('div',{staticClass:"text-text-color text-xs"},[_vm._v(" "+_vm._s(_vm.txCount)+" "+_vm._s(_vm.txCount === 1 ? _vm.$t("lang.block.tx") : _vm.$t("lang.block.txs"))+" ")]),_c('div',{staticClass:"text-text-color text-xs"},[_vm._v(" "+_vm._s(_vm._f("formatTime")(_vm.blockTime))+" ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }